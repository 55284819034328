import { render, staticRenderFns } from "./SidePanel.vue?vue&type=template&id=44a6ff38"
import script from "./SidePanel.vue?vue&type=script&lang=js"
export * from "./SidePanel.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "@/locales/common.yaml?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fbuilds%2Fbig-dig-data%2Fcelus%2Fdesign%2Fui%2Fsrc%2Fpages%2FSidePanel.vue&lang=yaml&external"
if (typeof block0 === 'function') block0(component)
import block1 from "@/locales/notifications.yaml?vue&type=custom&index=1&blockType=i18n&issuerPath=%2Fbuilds%2Fbig-dig-data%2Fcelus%2Fdesign%2Fui%2Fsrc%2Fpages%2FSidePanel.vue&lang=yaml&external"
if (typeof block1 === 'function') block1(component)

export default component.exports