import { render, staticRenderFns } from "./MenuListItem.vue?vue&type=template&id=3737e792"
import script from "./MenuListItem.vue?vue&type=script&lang=js"
export * from "./MenuListItem.vue?vue&type=script&lang=js"
import style0 from "./MenuListItem.vue?vue&type=style&index=0&id=3737e792&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "@/locales/notifications.yaml?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fbuilds%2Fbig-dig-data%2Fcelus%2Fdesign%2Fui%2Fsrc%2Fcomponents%2Futil%2FMenuListItem.vue&lang=yaml&external"
if (typeof block0 === 'function') block0(component)

export default component.exports