import { render, staticRenderFns } from "./StandardLayout.vue?vue&type=template&id=7b0a1234&scoped=true"
import script from "./StandardLayout.vue?vue&type=script&lang=js"
export * from "./StandardLayout.vue?vue&type=script&lang=js"
import style0 from "./StandardLayout.vue?vue&type=style&index=0&id=7b0a1234&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7b0a1234",
  null
  
)

/* custom blocks */
import block0 from "@/locales/dialog.yaml?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fbuilds%2Fbig-dig-data%2Fcelus%2Fdesign%2Fui%2Fsrc%2Fpages%2FStandardLayout.vue&lang=yaml&external"
if (typeof block0 === 'function') block0(component)
import block1 from "@/locales/common.yaml?vue&type=custom&index=1&blockType=i18n&issuerPath=%2Fbuilds%2Fbig-dig-data%2Fcelus%2Fdesign%2Fui%2Fsrc%2Fpages%2FStandardLayout.vue&lang=yaml&external"
if (typeof block1 === 'function') block1(component)
import block2 from "./StandardLayout.vue?vue&type=custom&index=2&blockType=i18n&lang=yaml"
if (typeof block2 === 'function') block2(component)

export default component.exports