import { render, staticRenderFns } from "./DateRangeText.vue?vue&type=template&id=67c424b8&scoped=true"
import script from "./DateRangeText.vue?vue&type=script&lang=js"
export * from "./DateRangeText.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "67c424b8",
  null
  
)

/* custom blocks */
import block0 from "@/locales/common.yaml?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fbuilds%2Fbig-dig-data%2Fcelus%2Fdesign%2Fui%2Fsrc%2Fcomponents%2Futil%2FDateRangeText.vue&lang=yaml&external"
if (typeof block0 === 'function') block0(component)

export default component.exports